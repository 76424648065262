import { getIconFromSlug, type AITool } from "@app/_metadata";
import { Card } from "@shared-components/shadcn/card";
import { cn } from "@shared-utils/ui";
import Link from "next/link";

export interface ToolCardProps extends AITool {}

export const ToolCard = (props: ToolCardProps) => {
  return (
    <Link className="group flex" href={props.slug}>
      <Card className="h-full w-full">
        <div
          className={cn(
            cn(props.meta.backgroundColor),
            "relative h-20 overflow-hidden p-2 text-lg text-white transition-all group-hover:opacity-90",
          )}
        >
          <strong>{props.meta.name}</strong>
          {getIconFromSlug(props.slug, {
            className: "absolute right-8 -top-4 w-20 h-20 opacity-30",
          })}
        </div>
        <div className="bg-background p-2">
          <p>{props.meta.description}</p>
        </div>
      </Card>
    </Link>
  );
};
